import { OperationTypes } from "@cfra-nextgen-frontend/shared/src/utils/enums";
import { UserPlatformManagementEntityTypes } from "@cfra-nextgen-frontend/shared/src/utils/enums";
import { FiltersData } from "../../Form/types/filters";
import { modifyFormRequestBody } from "../components/Profile/utils";

export const modifyRequestBodyFromFilterMetadata = ({
    filtersData, requestBody, entityType, operationType, entityId
}:{
    filtersData: FiltersData, 
    requestBody: Array<Record<string, any>> | Record<string, any>,
    entityType: UserPlatformManagementEntityTypes,
    operationType:OperationTypes,
    entityId: number | undefined
}) => {
    let metaData = filtersData.filter_metadata;

    Object.keys(metaData)
        .filter(
            (key) =>
                (metaData[key].dropField?.create || false) &&
                metaData[key].item_metadata.response_mapping?.default !== undefined,
        )
        .forEach((key) => {
            let fieldName = metaData[key].item_metadata.response_mapping?.default;
            if (fieldName !== undefined) {
                if (Array.isArray(requestBody)) {
                    requestBody.forEach((item) => {
                        delete item[fieldName!];
                    });
                    return;
                }
                delete requestBody[fieldName];
            }
            requestBody = modifyFormRequestBody(requestBody, entityType, operationType, entityId);
            return '';
        });
    
    if (entityType === UserPlatformManagementEntityTypes.Group && operationType === OperationTypes.CREATE) {
        if (!Array.isArray(requestBody)) {
            requestBody['account_id'] = entityId;
        }
    }

    // NEEDED - for product_type selection in packages - to be done in a future story  
    // if (entityType === UserPlatformManagementEntityTypes.Package && operationType === OperationTypes.CREATE) {
    //     if (!Array.isArray(requestBody)) {
    //         if (requestBody['product_lid']) {
    //             requestBody['product_lid'] = requestBody['product_lid']["key"]                      
    //         }
    //     }
    // }
    
    return requestBody;
};