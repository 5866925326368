import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import { BulletMessage } from '@cfra-nextgen-frontend/shared/src/components/BulletMessage';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard/ETFCard';
import { NoInformationAvailable } from '@cfra-nextgen-frontend/shared/src/components/ETFCard/ETFEmptyCard';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { CancelWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CancelWithConfirmation';
import { OperateEntityWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/OperateEntityWithConfirmation';
import {
    SingleModalProps,
    getEntityFilterReqParams,
    getFilterPreRequestParams,
    getFiltersMetadataByViewType,
    getModifyDeleteRequestFunction,
    getRequestBody,
    modifyFormRequestBody,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/utils';
import {
    checkIfFilterInfoIsAvailable,
    noInformationAvailableCardStyles,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/utils';
import { MultipleModalsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/MultipleModalsContext/MultipleModalsContext';
import '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/etfScreenerFilterSearch/FiltersForm.scss';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { BasicForm, PublicBasicFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/BasicForm';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import {
    fillTemplate,
    replaceAllPlaceholdersInObject,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import {
    OperateEntityStatuses,
    OperationTypes,
    RequestTypes,
    UserPlatformManagementEntityTypes,
    serverErrorToBeautifiedMessage,
} from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { Box } from '@mui/material';
import _, { cloneDeep, intersection, merge } from 'lodash';
import { useSnackbar } from 'notistack';
import { Dispatch, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query/types/react/types';
import { ScreenerData } from '../types/screener';
import { modifyRequestBodyFromFilterMetadata } from '../utils/form';

let formStateValues: any = {};

export type CreateFormProps = {
    entityType: UserPlatformManagementEntityTypes;
    entityId?: number;
    cancelButtonCallback: () => void;
    openConfirmationModal: boolean;
    setOpenConfirmationModal: Dispatch<boolean>;
    submitButtonName?: string;
    onCreationSuccessCallback?: (createdItemId?: number) => void;
    operationType: OperationTypes;
    requestPath?: string;
    onBeforeCreateSuccess?: (data: any, formData?: RhFormData, filterData?: FiltersData) => void;
    onOperationSuccessSnackMessage: SingleModalProps['onOperationSuccessSnackMessage'];
    multipleResultsPopupId?: SingleModalProps['multipleResultsPopupId'];
    multipleResultsPopup?: SingleModalProps['multipleResultsPopup'];
    multipleResultsPopupTitle?: SingleModalProps['multipleResultsPopupTitle'];
    maxNumberOfItemsPerOneRequest?: SingleModalProps['maxNumberOfItemsPerOneRequest'];
    presetValues?: SingleModalProps['presetValues'];
    localFiltersMetadata?: SingleModalProps['localFiltersMetadata'];
    useScreenerDataForFilters?: boolean;
    onSubmit?: (filtersData: FiltersData, formData: RhFormData, formDataState: Record<string, any>) => void;
} & Omit<PublicBasicFormProps, 'filtersData'>;

export function CreateForm({
    analyticsCardName,
    entityType,
    entityId,
    cancelButtonCallback,
    onOperationSuccessSnackMessage,
    openConfirmationModal,
    setOpenConfirmationModal,
    submitButtonName,
    onBeforeCreateSuccess,
    onCreationSuccessCallback,
    operationType,
    requestPath,
    multipleResultsPopupId,
    multipleResultsPopup,
    multipleResultsPopupTitle,
    maxNumberOfItemsPerOneRequest,
    presetValues,
    localFiltersMetadata,
    useScreenerDataForFilters
}: CreateFormProps) {
    const [formData, setFormData] = useState<RhFormData | undefined>();
    const [filtersData, setFiltersData] = useState<FiltersData>();
    const [requestBody, setRequestBody] = useState({} as any);
    const [formDataState, setFormDataState] = useState<Record<string, any>>();
    const { multipleModalsStateDispatcher } = useContext(MultipleModalsContext);

    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const { formState, control, getValues, setValue, handleSubmit, trigger, reset, resetField } = useForm({
        reValidateMode: 'onSubmit',
    });

    const { getFiltersData, getScreenerData } = useContext(ProjectSpecificResourcesContext);

    const filterPreRequestParams = getFilterPreRequestParams(entityType, operationType, entityId);

    const screenerQueryResult = getScreenerData?.({
        ...filterPreRequestParams,
        config: {
            enabled: Object.keys(filterPreRequestParams).length > 0,
        },
    }) as UseQueryResult<ScreenerData>;

    const filterPreRequestParamValues = useMemo(() => {
        if (!(screenerQueryResult?.data?.results?.data && screenerQueryResult.data.results.data.length > 0)) {
            return {};
        }

        return {
            filterPreRequestParamValues: screenerQueryResult.data.results.data[0],
        };
    }, [screenerQueryResult.data?.results.data]);

    const entityFiltersReqParams: SearchByParams = useMemo(() => {
        return replaceAllPlaceholdersInObject(
            getEntityFilterReqParams(entityType, operationType),
            filterPreRequestParamValues,
            true,
        );
    }, [filterPreRequestParamValues, entityType, operationType]);

    if (
        Object.keys(requestBody).length > 0 && presetValues && Object.keys(presetValues).length > 0
            ? true
            : Object.keys(formState.dirtyFields).length > 0 &&
              intersection(Object.keys(requestBody), Object.keys(formState.dirtyFields)).length > 0
    ) {
        entityFiltersReqParams['requestBody'] = getRequestBody(
            requestBody,
            {
                ...formState.dirtyFields,
                ...Object.keys(presetValues || {})?.reduce((acc, value) => {
                    acc[value] = true;
                    return acc;
                }, {} as any),
            },
            getValues,
        );
    }

    const filtersQueryResult = getFiltersData?.(entityFiltersReqParams) as UseQueryResult<FiltersData>;

    const modifyDeleteRequestFn = useMemo(
        () => getModifyDeleteRequestFunction(entityType, operationType, screenerQueryResult),
        [screenerQueryResult, entityType, operationType],
    );

    useEffect(() => {
        if (!filtersQueryResult.data) {
            return;
        }

        if (localFiltersMetadata && Object.keys(localFiltersMetadata).length > 0) {
            const filtersQueryResultDataDeepCopy = cloneDeep(filtersQueryResult.data);
            setFiltersData({
                ...filtersQueryResultDataDeepCopy,
                filter_metadata: merge(filtersQueryResultDataDeepCopy.filter_metadata, localFiltersMetadata),
            });
            return;
        }

        if (filtersQueryResult?.data?.filter_metadata && screenerQueryResult?.data?.results?.data?.length && useScreenerDataForFilters) {
            const filtersDataCopy: FiltersData = _.cloneDeep(filtersQueryResult.data || {});
            const { filtersMetadata } = getFiltersMetadataByViewType({
                screenerData: screenerQueryResult.data,
                filtersMetadata: filtersDataCopy?.filter_metadata,
            });

            filtersDataCopy['filter_metadata'] = filtersMetadata;

            setFiltersData(filtersDataCopy);
        } else {
            setFiltersData(filtersQueryResult.data);
        }
    }, [filtersQueryResult.data, screenerQueryResult?.data, localFiltersMetadata]);

    const handleDependencies = useMemo(
        () => Object.values(filtersQueryResult.data?.filter_metadata || {}).some((item) => Boolean(item.dependencies)),
        [filtersQueryResult.data?.filter_metadata],
    );

    const triggerHandleDependencies = useCallback(() => {
        if (!filtersQueryResult.data) {
            return;
        }

        let metaData = filtersQueryResult.data.filter_metadata;
        let request_body = {} as any;

        for (let key in metaData) {
            if ((metaData[key].dependencies || []).length > 0) {
                let new_key = combineIntoFormElementName({
                    componentName: metaData[key].component,
                    filterMetadataKey: key,
                });
                request_body[new_key] = {
                    query_arg: metaData[key].source_field || '',
                    resetFields: metaData[key].dependencies?.map((item) =>
                        combineIntoFormElementName({
                            componentName: metaData[item].component,
                            filterMetadataKey: item,
                        }),
                    ),
                };
            }
        }

        setRequestBody(request_body);
    }, [filtersQueryResult.data]);

    useEffect(() => {
        if (!handleDependencies) {
            return;
        }

        triggerHandleDependencies();
    }, [handleDependencies, triggerHandleDependencies, filtersQueryResult.data]);

    useEffect(() => {
        if (presetValues && Object.keys(presetValues).length > 0) {
            for (const key in presetValues) {
                if (presetValues[key]?.isDirty) {
                    setValue(key, presetValues[key]?.value, { shouldDirty: true });
                } else {
                    reset({ [key]: presetValues[key] });
                }
            }
            triggerHandleDependencies(); // re-handle dependencies after preset values are set
        }
    }, [reset, presetValues, triggerHandleDependencies, setValue]);

    const clearChildControlsOnParentChange = useCallback(
        (fieldName?: string) => {
            if (fieldName !== undefined && fieldName in requestBody) {
                for (let field of requestBody[fieldName].resetFields) {
                    if (field in formState.dirtyFields) {
                        resetField(field);
                    }
                }
            }
        },
        [requestBody, formState.dirtyFields, resetField],
    );

    const onCancel = useCallback(
        (resetAllValues: boolean = true) => {
            cancelButtonCallback();
            formStateValues = {}; //reset formState values on confirm/cancel of modal popups
            if (formState.isDirty || Object.keys(formState.errors).length > 0) {
                if (resetAllValues) reset();
                else reset(presetValues || formData);
            }
        },
        [cancelButtonCallback, formState.isDirty, reset, formState.errors, formData, presetValues],
    );

    const onOperationFailure = useCallback(
        (data: any) => {
            const beautifiedMessage =
                serverErrorToBeautifiedMessage[data?.message] ||
                `An error occurred while creating the item. ${data?.message}`;
            if (data?.isErroredResponse) {
                ShowSnack(
                    SnackMessageForm({
                        message: beautifiedMessage,
                    }),
                );
            }
        },
        [ShowSnack],
    );

    const onOperationSuccess = useCallback(
        (data: Array<any> | any) => {
            const showSnack = (message: string) =>
                ShowSnack(
                    SnackMessageForm({
                        message: message,
                    }),
                );

            const singleItemData = data?.[entityType];

            if (Array.isArray(data) && !singleItemData) {
                const successfullyOperated = data.filter(
                    (dataItem) => dataItem?.status === OperateEntityStatuses.SUCCESS,
                );
                const failedToOperate = data.filter((dataItem) => dataItem?.status === OperateEntityStatuses.FAILED);

                const numberOfItemsSuccessfullyOperated = successfullyOperated.length;
                const numberOfItemsFailedToOperate = failedToOperate.length;

                const listOfNotCreatedUsers = failedToOperate.map((data) => {
                    const message = Object.keys(serverErrorToBeautifiedMessage).includes(data?.message)
                        ? serverErrorToBeautifiedMessage[data?.message]
                        : data?.message;
                    return `${data?.data?.email}: ${message}`;
                });

                if (numberOfItemsFailedToOperate > 0) {
                    if (!multipleResultsPopupId || !multipleResultsPopup) {
                        throw new Error(
                            'multipleResultsPopupId and multipleResultsPopup is required when multiple results are returned',
                        );
                    }

                    const modalContent = (
                        <BulletMessage message={listOfNotCreatedUsers} style={{ marginBottom: '0px' }} />
                    );

                    const modalTitle = fillTemplate({
                        templateName: 'modalTitle',
                        template: multipleResultsPopupTitle || '',
                        dataObject: {
                            numberOfItemsFailedToOperate,
                        },
                    });

                    multipleModalsStateDispatcher({
                        type: 'complementModalsState',
                        newState: {
                            modals: {
                                [multipleResultsPopupId]: {
                                    isOpen: true,
                                    title: modalTitle,
                                    content: modalContent,
                                },
                            },
                        },
                    });
                }

                if (numberOfItemsSuccessfullyOperated > 0) {
                    onBeforeCreateSuccess?.(successfullyOperated, formData, filtersQueryResult?.data);

                    showSnack(
                        fillTemplate({
                            templateName: 'onOperationSuccessSnackMessage',
                            template: onOperationSuccessSnackMessage,
                            dataObject: {
                                count: numberOfItemsSuccessfullyOperated,
                                countBasedEnding: numberOfItemsSuccessfullyOperated > 1 ? 's' : '',
                            },
                        }) || '',
                    );
                }

                onCancel(false);
                onCreationSuccessCallback?.();
                return;
            }

            let id = singleItemData?.id;
            if (Array.isArray(singleItemData) && singleItemData.length > 0) {
                id = singleItemData[0]?.id;
            }

            if (!id) {
                return;
            }

            onBeforeCreateSuccess?.(data, formData, filtersQueryResult?.data);

            showSnack(
                fillTemplate({
                    templateName: 'onOperationSuccessSnackMessage',
                    template: onOperationSuccessSnackMessage,
                    dataObject: data?.[entityType],
                }) || '',
            );

            onCancel(false);
            onCreationSuccessCallback?.(id);
        },
        [
            multipleResultsPopupTitle,
            multipleModalsStateDispatcher,
            ShowSnack,
            onCancel,
            onOperationSuccessSnackMessage,
            entityType,
            onCreationSuccessCallback,
            filtersQueryResult?.data,
            onBeforeCreateSuccess,
            formData,
            multipleResultsPopupId,
            multipleResultsPopup,
        ],
    );

    const enableSubmitButton = useMemo(
        () => Boolean(formState.isDirty && formState.isValid),
        [formState.isDirty, formState.isValid],
    );

    const filterInfoNotAvailable = useMemo(
        () => checkIfFilterInfoIsAvailable(filtersQueryResult.isLoading, filtersQueryResult.data),
        [filtersQueryResult.isLoading, filtersQueryResult.data],
    );

    const isDirty = useMemo(() => {
        //we want preset values that are included in the response to be part of the 'isDirty' check.
        const filteredPresetValues = presetValues ? Object.entries(presetValues).filter(([key, value]) => !value?.isDirty).map(([key])=>key): [];
        return (
            formState.isDirty &&
            Object.keys(formState.dirtyFields).filter((key) => !filteredPresetValues.includes(key)).length >
                0
        );
    }, [presetValues, formState.dirtyFields, formState.isDirty]);

    if (!filtersData && filtersQueryResult.isLoading) {
        return <ETFCard isLoading={filtersQueryResult.isLoading} />;
    }

    if (!filtersData || filterInfoNotAvailable) {
        return <NoInformationAvailable sx={noInformationAvailableCardStyles} />;
    }

    const emitControlValues = (value: any) => {
        setFormDataState((prevFormDataState) => ({ ...prevFormDataState, ...value }));
    };

    const emitDefaultValues = (value: any) => {
        // we need to reset form to set default values again once form is initialize.
        // https://stackoverflow.com/questions/64306943/defaultvalues-of-react-hook-form-is-not-setting-the-values-to-the-input-fields-i

        if (Object.keys(value).length > 0) {
            let callReset = false;
            for (let i = 0; i < Object.keys(value).length; i++) {
                const key = Object.keys(value)[i];
                if (!_.isEqual(value[key], formStateValues[key])) {
                    callReset = true;
                    break;
                }
            }
            if (callReset) {
                formStateValues = { ...formStateValues, ...value };
                if (Object.keys(formStateValues).length > 0) {
                    reset(formStateValues);
                }
            }
        }
    };

    const modifyRequestBody = (requestBody: Array<Record<string, any>> | Record<string, any>) => modifyRequestBodyFromFilterMetadata({
            requestBody,
            filtersData,
            entityType,
            operationType,
            entityId
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}>
            <BasicForm
                filtersData={filtersData}
                analyticsCardName={`${analyticsCardName} Form`}
                getValues={getValues}
                control={control}
                setValue={setValue}
                handleSubmit={handleSubmit}
                setFormData={setFormData}
                trigger={trigger}
                onChangeClearHandler={clearChildControlsOnParentChange}
                emitControlValues={emitControlValues}
                emitDefaultValues={emitDefaultValues}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    gap: '1rem',
                    paddingX: 3.75,
                    paddingTop: 1,
                    paddingBottom: 4,
                }}>
                <CancelWithConfirmation
                    confirmModalText='Your changes will not be saved. Proceed?'
                    isDirty={isDirty}
                    analyticsCardName={`${analyticsCardName}CancelConfirm`}
                    onCancel={onCancel}
                    externalOpenConfirmationModal={openConfirmationModal}
                    setExternalOpenConfirmationModal={setOpenConfirmationModal}
                />
                <OperateEntityWithConfirmation
                    requestType={RequestTypes.POST}
                    requestPath={requestPath || entityType}
                    disableConfirmationModal
                    enableSubmitButton={enableSubmitButton}
                    analyticsCardName={`${analyticsCardName}SaveConfirm`}
                    dirtyFields={formState.dirtyFields}
                    formData={formData}
                    filtersData={filtersData}
                    onOperationSuccess={onOperationSuccess}
                    onOperationFailure={onOperationFailure}
                    saveButtonText={submitButtonName}
                    modifyRequestBody={modifyRequestBody}
                    operationType={operationType}
                    formDataState={formDataState}
                    modifyDeleteRequestFn={modifyDeleteRequestFn}
                    externalJsx={<>{multipleResultsPopup}</>}
                    maxNumberOfItemsPerOneRequest={maxNumberOfItemsPerOneRequest}
                />
            </Box>
        </Box>
    );
}
