import { DefaultCFRASnack, RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2BorderRadius4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CancelWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CancelWithConfirmation';
import { OperateEntityWithConfirmation } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/OperateEntityWithConfirmation';
import '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/etfScreenerFilterSearch/FiltersForm.scss';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { RequestTypes, serverErrorToBeautifiedMessage } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { FormState } from 'react-hook-form';

export function EditButtonsVariant1({
    analyticsCardName,
    edit,
    editButtonText,
    filtersData,
    formData,
    formState,
    modifyRequestBody,
    onEditChange,
    onUpdate,
    requestPath,
    updateConfirmTitle,
    sectionKey,
    requestDefaultValue,
}: {
    analyticsCardName: string;
    edit: boolean;
    editButtonText: string;
    filtersData: FiltersData;
    formData?: RhFormData;
    formState: FormState<any>;
    modifyRequestBody?: (requestBody: { [key: string]: any }) => { [key: string]: any };
    onEditChange: (v: boolean) => void;
    onUpdate: (data: any) => void;
    requestPath: string;
    updateConfirmTitle?: string;
    sectionKey?: string;
    requestDefaultValue?: Record<string, any>;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const enableSubmitButton = useMemo(
        () => Boolean(formState.isDirty && formState.isValid),
        [formState.isDirty, formState.isValid],
    );

    const handleOnEditClick = useCallback(() => {
        onEditChange(true);
        globalThis.analytics?.registerAction?.({
            action: `click on ${editButtonText}`,
            cardName: analyticsCardName,
        });
    }, [analyticsCardName, onEditChange, editButtonText]);

    const editButton = useMemo(() => {
        return (
            <RoundedTextButton
                key={`${requestPath}EditBtn`}
                theme={roundedTextButtonThemeV2BorderRadius4}
                buttonText={editButtonText}
                onClickCallback={handleOnEditClick}
            />
        );
    }, [editButtonText, requestPath, handleOnEditClick]);

    const onOperationFailure = useCallback(
        (data: any) => {
            const beautifiedMessage =
                serverErrorToBeautifiedMessage[data?.message] ||
                `An error occurred while editing the item. ${data?.message}`;
            if (data?.isErroredResponse) {
                ShowSnack(
                    SnackMessageForm({
                        message: beautifiedMessage,
                    }),
                );
            }
        },
        [ShowSnack],
    );

    const operateEntityWithConfirmation = useMemo(() => {
        return (
            <OperateEntityWithConfirmation
                requestType={RequestTypes.PUT}
                requestPath={requestPath}
                confirmModalText={updateConfirmTitle || 'Are you sure you want to update?'}
                enableSubmitButton={Boolean(enableSubmitButton)}
                analyticsCardName={`${analyticsCardName}SaveConfirm`}
                dirtyFields={formState.dirtyFields}
                formData={formData}
                filtersData={filtersData}
                onOperationSuccess={onUpdate}
                onOperationFailure={onOperationFailure}
                modifyRequestBody={modifyRequestBody}
                sectionKey={sectionKey}
                requestDefaultValue={requestDefaultValue}
            />
        );
    }, [
        analyticsCardName,
        enableSubmitButton,
        filtersData,
        formData,
        modifyRequestBody,
        requestPath,
        updateConfirmTitle,
        formState.dirtyFields,
        onUpdate,
        sectionKey,
        requestDefaultValue,
        onOperationFailure,
    ]);

    const cancelWithConfirmation = useMemo(() => {
        return (
            <CancelWithConfirmation
                confirmModalText='Your changes will not be saved. Proceed?'
                isDirty={formState.isDirty}
                analyticsCardName={`${analyticsCardName}CancelConfirm`}
                onCancel={() => onEditChange(false)}
                preventChangingRoute
            />
        );
    }, [analyticsCardName, onEditChange, formState.isDirty]);

    return (
        <>
            {!edit && editButton}
            {edit && (
                <Box sx={{ display: 'flex', justifyContent: 'left', gap: '21px', p: 0 }}>
                    {cancelWithConfirmation}
                    {operateEntityWithConfirmation}
                </Box>
            )}
        </>
    );
}
