import FormAutocompleteFreesolo from '@cfra-nextgen-frontend/shared/src/components/Form/FormAutocompleteFreesolo';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { getHookFormValidationRules } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { useEffect, useMemo } from 'react';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

type ScreenerFormAutocompleteFreesoloProps = {} & FilterProps;

export const ScreenerFormAutocompleteFreesolo: React.FC<ScreenerFormAutocompleteFreesoloProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    component,
    fieldDefaultStyles = {},
    parentSectionKey,
    onChangeClearHandler,
    emitDefaultValues,
    doNotSetDefaultValue
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const defaultValue = !doNotSetDefaultValue ? Array.isArray(filterMetadata.default_value)
        ? filterMetadata.default_value.join(',')
        : ((filterMetadata.default_value || '') as string) : undefined;
    const options = filtersData.data?.[filterMetadataKey].items?.map((o) => o.value);
    const validationRules = getHookFormValidationRules(filtersData, filterMetadataKey, parentSectionKey);
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    useEffect(() => {
        if (defaultValue && emitDefaultValues) {
            emitDefaultValues({
                [combineIntoFormElementName({
                    componentName: component || Components.AutoCompleteFreeSolo,
                    filterMetadataKey,
                })]: defaultValue,
            });
        }
    }, [defaultValue, emitDefaultValues, component, filterMetadataKey]);

    const filterProps = useMemo(() => ({
        control: control,
        name: combineIntoFormElementName({
            componentName: component || Components.AutoCompleteFreeSolo,
            filterMetadataKey,
        }),
        defaultValue,
        validationRules: validationRules,
        options: options,
        placeholder: filterMetadata.placeholder || label,
        submitHandler: submitHandler,
        fieldDefaultStyles: fieldDefaultStyles,
        onChangeClearHandler: onChangeClearHandler,
        disabled: filterMetadata.disabled,
        readOnly: filterMetadata.makeReadOnlyOnDefaultValues && defaultValue ? true : false,
        forcePopupIcon: filterMetadata.forcePopupIcon
    }), [
        component, control, defaultValue, fieldDefaultStyles, 
        filterMetadata.disabled, filterMetadata.forcePopupIcon, 
        filterMetadata.makeReadOnlyOnDefaultValues, filterMetadata.placeholder, 
        filterMetadataKey, label, onChangeClearHandler, options, 
        submitHandler, validationRules
    ]);

    const filter = (
        <FormAutocompleteFreesolo
            {...filterProps}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            hide={hide}
            {...(filterSectionMetadata?.stack_label_and_filter && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
